import React from 'react';
import { FaArrowRight, FaChartLine, FaMoneyBillWave, FaHandHoldingUsd, FaPercentage } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './OnePercentListsSection.css';

const OnePercentListsSection = () => {
  const navigate = useNavigate();

  return (
    <div className="one-percent-lists-section">
      <div className="one-percent-header">
        <h2>Why Choose 1% Lists?</h2>
        <p>Our innovative model helps you attract more clients and close more deals in today's competitive market</p>
      </div>

      <div className="one-percent-grid">
        <div className="one-percent-card">
          <div className="one-percent-card-icon">
            <FaPercentage size={24} color="#333" />
          </div>
          <h3>1% Listing Fee Advantage</h3>
          <p>Sellers save thousands with our low commission structure, making you their first choice in a competitive market.</p>
          
          <ul className="one-percent-benefits">
            <li className="one-percent-benefit-item">
              <div className="one-percent-benefit-bullet">1</div>
              <div className="one-percent-benefit-text">
                <strong>More Listings</strong> - Become the go-to agent for cost-conscious sellers
              </div>
            </li>
            <li className="one-percent-benefit-item">
              <div className="one-percent-benefit-bullet">2</div>
              <div className="one-percent-benefit-text">
                <strong>Faster Sales</strong> - Competitive pricing attracts more buyers
              </div>
            </li>
            <li className="one-percent-benefit-item">
              <div className="one-percent-benefit-bullet">3</div>
              <div className="one-percent-benefit-text">
                <strong>Higher Volume</strong> - More transactions mean more total commissions
              </div>
            </li>
          </ul>
        </div>

        <div className="one-percent-card">
          <div className="one-percent-card-icon">
            <FaMoneyBillWave size={24} color="#333" />
          </div>
          <h3>$5,000 Buyer Cashback</h3>
          <p>Stand out from competitors by offering buyers significant savings on their purchase.</p>
          
          <ul className="one-percent-benefits">
            <li className="one-percent-benefit-item">
              <div className="one-percent-benefit-bullet">1</div>
              <div className="one-percent-benefit-text">
                <strong>More Buyer Leads</strong> - Attract clients looking to maximize savings
              </div>
            </li>
            <li className="one-percent-benefit-item">
              <div className="one-percent-benefit-bullet">2</div>
              <div className="one-percent-benefit-text">
                <strong>Higher Conversion</strong> - Cashback helps overcome price objections
              </div>
            </li>
            <li className="one-percent-benefit-item">
              <div className="one-percent-benefit-bullet">3</div>
              <div className="one-percent-benefit-text">
                <strong>Repeat Business</strong> - Happy clients refer friends and family
              </div>
            </li>
          </ul>
        </div>

        <div className="one-percent-cta">
          <p>In today's market, value wins - be the agent who delivers real savings!</p>
          <button 
            className="one-percent-join-btn" 
            onClick={() => navigate('/contact')}
          >
            Join the 1% Movement <FaArrowRight className="one-percent-btn-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnePercentListsSection;