import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';

import axios from '../services/axios';

import './Listings.css';

import AuthContext from '../context/AuthContext';

import { ReactComponent as BedroomIcon } from '../assets/icons/bedroom-3.svg';

import { ReactComponent as BathroomIcon } from '../assets/icons/bathroom.svg';

import ListingDetail from './ListingDetail';

import { FaShareAlt } from 'react-icons/fa';

import { FaSearch } from 'react-icons/fa';

import SharePopup from './SharePopup';  // Import the new SharePopup component

import SEO from './SEO';  // Import SEO component

import './SharePopup.css';

import { initialCities } from './cities';

import { neighborhoods } from './neighborhoods';

import StickyBottomBar from './StickyBottomBar';



const Listings = () => {

  const [currentPage, setCurrentPage] = useState(1);

  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000000 });

  const [showMobileFilters, setShowMobileFilters] = useState(false);

  const [manualSearchQuery, setManualSearchQuery] = useState(''); // Move this up

    // Add a new state to track if the search has been applied

const [searchApplied, setSearchApplied] = useState(false);

  const toggleMobileFilters = () => {

    setShowMobileFilters(!showMobileFilters);

  };

  const [selectedNeighborhood, setSelectedNeighborhood] = useState('All');

  const [tempPropertyTypes, setTempPropertyTypes] = useState([]);

  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);

  const [isPropertyTypeOpen, setIsPropertyTypeOpen] = useState(false);

  const [selectedCity, setSelectedCity] = useState('All');

  const [selectedBedrooms, setSelectedBedrooms] = useState('Any');

  const [selectedBathrooms, setSelectedBathrooms] = useState('Any');

  const [searchQuery, setSearchQuery] = useState('');

  const [selectedListing, setSelectedListing] = useState(null);

  const [filteredListings, setFilteredListings] = useState([]);

  const [error, setError] = useState(null);

  const searchTimeoutRef = useRef(null);  // Use ref to store timeout ID

  const [loading, setLoading] = useState(true);

  const [filteredCities, setFilteredCities] = useState([]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isInitialMount = useRef(true);

  const [filters, setFilters] = useState({

    city: 'All',

    bedrooms: 'Any',

    bathrooms: 'Any',

    style: 'All',

    searchQuery: '',

    priceRange: { min: 0, max: 10000000 },

    propertyTypes: [],

    currentPage: 1  // Make sure this is initialized

  });

  

  const PROPERTY_INFO_CODES = {

    House: ['.6.', '.D.', '.F.', '.G.', '.J.', '.K.', '.L.', '.M.', '.N.', '.R.', '.S.', '.X.'],

    Condo: ['.2.', '.3.', '.4.', '.C.', '.E.', '.H.', '.P.', '.W.', '.Z.'],

    Townhouse: ['.A.', '.T.'],

  };


  

  

  const PerformanceMonitor = {

    startTime: {},

    measurements: {},

    

    start: (processName) => {

      PerformanceMonitor.startTime[processName] = performance.now();

    },

    

    end: (processName) => {

      const endTime = performance.now();

      const startTime = PerformanceMonitor.startTime[processName];

      if (startTime) {

        const duration = endTime - startTime;

        if (!PerformanceMonitor.measurements[processName]) {

          PerformanceMonitor.measurements[processName] = [];

        }

        PerformanceMonitor.measurements[processName].push(duration);

        console.log(`%c${processName} took ${duration.toFixed(2)}ms`, 'color: #2563eb; font-weight: bold;');

        delete PerformanceMonitor.startTime[processName];

        

        // Calculate and log average if we have multiple measurements

        if (PerformanceMonitor.measurements[processName].length > 1) {

          const avg = PerformanceMonitor.measurements[processName].reduce((a, b) => a + b, 0) / 

                     PerformanceMonitor.measurements[processName].length;

          console.log(`%c${processName} average: ${avg.toFixed(2)}ms over ${PerformanceMonitor.measurements[processName].length} calls`, 

                     'color: #10B981; font-style: italic;');

        }

      }

    }

  };



  const [selectedStyle, setSelectedStyle] = useState('All');

  

  const initialBedrooms = useMemo(() => [...Array(9).keys()].map(num => (num + 1).toString()), []);

  const initialBathrooms = useMemo(() => [...Array(10).keys()].map(num => (num + 1).toString()), []);

  const [initialZoomLevel] = useState(8);

  const mapInstance = useRef(null);

  const desktopDropdownRef = useRef(null);

  const mobileDropdownRef = useRef(null);

  const listingsPerPage =  20;

  const [hasMore, setHasMore] = useState(true);


  const { user } = useContext(AuthContext);


  const handleCitySelection = (selection) => {

    const selectedItem = selection.label.split(',')[0]; // Extract the neighborhood/city name

  

    if (selection.type === 'City') {

      setSearchQuery('');

      setSelectedCity(selectedItem);

      setFilters(prev => ({ ...prev, city: selectedItem }));

      setSelectedNeighborhood('');

      setManualSearchQuery('');

      setSearchApplied(false);

    } else if (selection.type === 'Neighborhood') {

      setSearchQuery('');

      setSelectedCity('');

      setSelectedNeighborhood(selectedItem);

      setManualSearchQuery('');

      setSearchApplied(false);

    } else if ((selection.type === 'Address' || selection.type === 'MLS') && selection.listingId) {

      // For MLS listings, use the MLS number as both the ID and slug

      if (selection.type === 'MLS') {

        const addressSlug = selectedItem.replace(/ /g, '-').replace(/[^a-zA-Z0-9\-]/g, '');

        const listingUrl = `/listing/${selection.listingId}/${addressSlug}`;

        window.location.href = listingUrl;

      } else {

        // For regular addresses, use the address as the slug

        const addressSlug = selectedItem.replace(/ /g, '-').replace(/[^a-zA-Z0-9\-]/g, '');

        const listingUrl = `/listing/${selection.listingId}/${addressSlug}`;

        window.location.href = listingUrl;

      }

    }

  

    setFilteredCities([]);

  };

  


  // Close the dropdown if clicked outside

  useEffect(() => {

    const handleClickOutside = (event) => {

      if ((!desktopDropdownRef.current || !desktopDropdownRef.current.contains(event.target)) &&

          (!mobileDropdownRef.current || !mobileDropdownRef.current.contains(event.target))) {

        setFilteredCities([]);

      }

    };

  

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);

  }, []);

  



  useEffect(() => {

    const initializeListings = async () => {

      if (!isFirstLoad) return;

      

      PerformanceMonitor.start('Initial Component Load');

      

      const queryParams = new URLSearchParams(window.location.search);

      const cityFromUrl = queryParams.get('city');

      const propertyInfoFromUrl = queryParams.get('property_info');

  

      // Function to determine property types from property info codes

      const getPropertyTypesFromCodes = (codes) => {

        if (!codes) return [];

        

        const codesList = codes.split(',');

        

        // Use Set to ensure unique codes

        const uniqueCodes = new Set(codesList);

        

        // Find exact matches for property types

        let matchedTypes = [];

        

        // Check each code against property type definitions

        uniqueCodes.forEach(code => {

          if (PROPERTY_INFO_CODES.House.includes(code)) {

            matchedTypes.push('House');

          } else if (PROPERTY_INFO_CODES.Condo.includes(code)) {

            matchedTypes.push('Condo');

          } else if (PROPERTY_INFO_CODES.Townhouse.includes(code)) {

            matchedTypes.push('Townhouse');

          }

        });

        

        // Remove duplicates and return

        return [...new Set(matchedTypes)];

      };

  

      const initialPropertyTypes = getPropertyTypesFromCodes(propertyInfoFromUrl);

      

      const initialState = {

        city: cityFromUrl || 'All',

        priceRange: {

          min: parseInt(queryParams.get('minPrice'), 10) || 0,

          max: parseInt(queryParams.get('maxPrice'), 10) || 10000000

        },

        bedrooms: queryParams.get('bedrooms') || 'Any',

        bathrooms: queryParams.get('bathrooms') || 'Any',

        search: queryParams.get('search') || '',

        page: parseInt(queryParams.get('page'), 10) || 1,

        propertyTypes: initialPropertyTypes

      };

  

      // Set filters and individual state values

      setFilters(initialState);

      if (cityFromUrl) {

        setSelectedCity(cityFromUrl);

      }

      if (initialPropertyTypes.length > 0) {

        setSelectedPropertyTypes(initialPropertyTypes);

        setTempPropertyTypes(initialPropertyTypes);

      }

      

      setIsFirstLoad(false);

      PerformanceMonitor.end('Initial Component Load');

    };

  

    initializeListings();

  }, []);


  const [showSharePopup, setShowSharePopup] = useState(false);

  const [currentListingUrl, setCurrentListingUrl] = useState('');


  const handleShare = (e) => {

    e.preventDefault();

    

    const addressSlug = selectedListing.address

      .replace(/ /g, '-')

      .replace(/[^a-zA-Z0-9\-]/g, '');

    const unitNum = selectedListing.apt_num ? `-${selectedListing.apt_num}` : '';

    const link = `${window.location.origin}/listing/${selectedListing.ml_num}/${addressSlug}${unitNum}`;

    

    setCurrentListingUrl(link);


    if (window.innerWidth > 768) {  // Desktop

      setShowSharePopup(true);

    } else {  // Mobile

      if (navigator.share) {

        navigator.share({

          title: 'Check out this listing',

          url: link

        }).catch(console.error);

      } else {

        // Fallback for mobile browsers that don't support Web Share API

        setShowSharePopup(true);

      }

    }

  };

  

  // In the useEffect for data fetching, modify it to properly handle the property codes:

  useEffect(() => {

    if (isFirstLoad) return;

  

    const controller = new AbortController();

    

    const fetchData = async () => {

      try {

        PerformanceMonitor.start('Fetch Cycle');

        setLoading(true);

        

        const selectedCodes = selectedPropertyTypes

          .flatMap((type) => PROPERTY_INFO_CODES[type] || [])

          .join(',');

  

        const params = {

          limit: listingsPerPage + 1,

          offset: (filters.currentPage - 1) * listingsPerPage,

          city: selectedCity !== 'All' ? selectedCity : null,  // Use selectedCity directly

          community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,

          bedrooms: filters.bedrooms !== 'Any' ? filters.bedrooms : null,

          bathrooms: filters.bathrooms !== 'Any' ? filters.bathrooms : null,

          style: filters.style !== 'All' ? filters.style : null,

          minPrice: filters.priceRange.min,

          maxPrice: filters.priceRange.max,

          search: filters.searchQuery || null,

          property_info: selectedCodes || null,

          ...(filters.mlNum ? { ml_num: filters.mlNum } : {})

        };

  

        const response = await axios.get('/listings', {

          params,

          signal: controller.signal

        });

  

        setHasMore(response.data.length > listingsPerPage);

        setFilteredListings(response.data.slice(0, listingsPerPage));

      } catch (error) {

        if (error.name === 'AbortError') return;

        console.error('Error fetching listings:', error);

        setError('Failed to fetch listings');

      } finally {

        setLoading(false);

        PerformanceMonitor.end('Fetch Cycle');

      }

    };

    

    const timeoutId = setTimeout(fetchData, 300);

    

    return () => {

      controller.abort();

      clearTimeout(timeoutId);

    };

  }, [filters, selectedPropertyTypes, selectedNeighborhood, selectedCity]); // Added selectedCity dependency


  // Update filters with a single function

  const updateFilters = (newFilters) => {

    setFilters(prev => ({

      ...prev,

      ...newFilters,

      currentPage: 1 // Reset page when filters change

    }));


    // Update URL

    const queryParams = new URLSearchParams({

      city: newFilters.city || filters.city,

      minPrice: newFilters.priceRange?.min || filters.priceRange.min,

      maxPrice: newFilters.priceRange?.max || filters.priceRange.max,

      bedrooms: newFilters.bedrooms || filters.bedrooms,

      bathrooms: newFilters.bathrooms || filters.bathrooms,

      style: newFilters.style || filters.style,

      search: newFilters.searchQuery || filters.searchQuery,

      page: 1

    }).toString();

    

    window.history.pushState(null, '', `/listings?${queryParams}`);

  };


  

  const currentRequest = useRef(null);



  

  const fetchPageListings = async (isInitialLoad = false) => {

    PerformanceMonitor.start('fetchPageListings');

  

    // Only use AbortController for non-initial loads

    if (!isInitialLoad) {

      if (currentRequest.current) {

        currentRequest.current.abort();

      }

      currentRequest.current = new AbortController();

    }

  

    try {

      const pageOffset = (currentPage - 1) * listingsPerPage;

      const selectedCodes = selectedPropertyTypes

        .flatMap((type) => PROPERTY_INFO_CODES[type] || [])

        .join(',');

      

      // Only apply city filter if no neighborhood is selected

      const cityParam = selectedNeighborhood ? null : (selectedCity !== 'All' ? selectedCity : null);

      

      PerformanceMonitor.start('API Call - Fetch Listings');

      const pageResponse = await axios.get('/listings', {

        params: {

          limit: listingsPerPage + 1,

          offset: pageOffset,

          city: cityParam,

          community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,

          bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,

          bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,

          style: selectedStyle !== 'All' ? selectedStyle : null,

          minPrice: priceRange.min,

          maxPrice: priceRange.max,

          property_info: selectedCodes || null,

          search: searchQuery || null

        },

        ...((!isInitialLoad && currentRequest.current) ? { signal: currentRequest.current.signal } : {})

      });

      PerformanceMonitor.end('API Call - Fetch Listings');

  

      PerformanceMonitor.start('Process Listings Data');

      setFilteredListings(pageResponse.data.slice(0, listingsPerPage));

      PerformanceMonitor.end('Process Listings Data');

    } catch (error) {

      if (error.name === 'AbortError') {

        // Ignore abort errors

        return;

      }

      console.error('Error fetching page listings:', error);

      throw error;

    } finally {

      if (!isInitialLoad && currentRequest.current) {

        currentRequest.current = null;

      }

      PerformanceMonitor.end('fetchPageListings');

    }

  };


useEffect(() => {

    // After listings are loaded, update currentPage based on query params if necessary

    const queryParams = new URLSearchParams(window.location.search);

    const pageFromURL = parseInt(queryParams.get('page'), 10);

    if (pageFromURL && pageFromURL !== currentPage) {

      setCurrentPage(pageFromURL); // Correct the page after data is loaded

    }

  }, [loading]);

  

  useEffect(() => {

    setCurrentPage(1);

    setFilteredListings([]);


    if (mapInstance.current) {

      mapInstance.current.setZoom(initialZoomLevel);

    }

  }, [selectedCity, selectedBedrooms, selectedBathrooms, searchQuery, priceRange]);

  

  const formatPrice = (price) => {

    return `$${Number(price).toLocaleString()}`;

  };


  // Update handleFilterChange to properly handle city changes

const handleFilterChange = (setter, filterType) => (value) => {

  PerformanceMonitor.start(`Filter Change - ${filterType}`);

  

  // Update both the individual state for UI

  setter(value);

  

  // Update filters state for data fetching

  setFilters(prev => {

    let newFilters = { ...prev };

    

    switch(filterType) {

      case 'city':

        newFilters.city = value !== 'All' ? value : null;

        break;

      case 'bedrooms':

        newFilters.bedrooms = value !== 'Any' ? value : null;

        break;

      case 'bathrooms':

        newFilters.bathrooms = value !== 'Any' ? value : null;

        break;

      case 'priceRange':

        newFilters.priceRange = value;

        break;

      default:

        break;

    }

    

    // Reset page when filters change

    newFilters.currentPage = 1;

    return newFilters;

  });


  // Update URL

  const queryParams = new URLSearchParams({

    city: filterType === 'city' ? value : selectedCity,

    minPrice: priceRange.min,

    maxPrice: priceRange.max,

    bedrooms: filterType === 'bedrooms' ? value : selectedBedrooms,

    bathrooms: filterType === 'bathrooms' ? value : selectedBathrooms,

    style: filterType === 'style' ? value : selectedStyle,

    search: searchQuery,

    property_search: selectedPropertyTypes.length > 0 ? selectedPropertyTypes.join(',') : '',

    page: 1,

  }).toString();


  window.history.pushState(null, '', `/listings?${queryParams}`);

  PerformanceMonitor.end(`Filter Change - ${filterType}`);

};

  


  const paginate = (pageNumber) => {

    // Don't allow going below page 1

    if (pageNumber < 1) return;

    

    // Don't allow going forward if there are no more items

    if (pageNumber > currentPage && !hasMore) return;

  

    // Get property info codes for selected property types

    const selectedCodes = selectedPropertyTypes

      .flatMap((type) => PROPERTY_INFO_CODES[type] || [])

      .join(',');

  

    // Update both page states

    setCurrentPage(pageNumber);

    setFilters(prev => ({

      ...prev,

      currentPage: pageNumber

    }));

  

    // Update URL with all current filters and new page number

    const queryParams = new URLSearchParams({

      city: selectedCity,

      minPrice: priceRange.min,

      maxPrice: priceRange.max,

      bedrooms: selectedBedrooms,

      bathrooms: selectedBathrooms,

      style: selectedStyle,

      search: searchQuery,

      property_info: selectedCodes || '',

      page: pageNumber

    }).toString();

  

    window.history.pushState(null, '', `/listings?${queryParams}`);

  };

  


const [uniqueCities] = useState(initialCities);


  // Add style to your clearFilters function

  const clearFilters = () => {

    // Reset all individual states

    setPriceRange({ min: 0, max: 10000000 });

    setSelectedCity('All');

    setSelectedBedrooms('Any');

    setSelectedBathrooms('Any');

    setSelectedStyle('All');

    setSearchQuery('');

    setManualSearchQuery('');

    setSearchApplied(false);

    setSelectedNeighborhood('');

    setCurrentPage(1);

    setSelectedPropertyTypes([]);

    setTempPropertyTypes([]);

    

    // Reset the filters state - this will trigger the useEffect and fetch new data

    setFilters({

      city: 'All',

      bedrooms: 'Any',

      bathrooms: 'Any',

      style: 'All',

      searchQuery: '',

      priceRange: { min: 0, max: 10000000 },

      propertyTypes: [],

      currentPage: 1

    });

  

    // Update URL

    const queryParams = new URLSearchParams({

      city: 'All',

      minPrice: 0,

      maxPrice: 10000000,

      bedrooms: 'Any',

      bathrooms: 'Any',

      style: 'All',

      search: '',

      property_info: '',

      page: 1

    }).toString();

    

    window.history.pushState(null, '', `/listings?${queryParams}`);

  };


  const removeFilter = (filterType) => {

    // Reset the appropriate individual state

    switch (filterType) {

      case 'city':

        setSelectedCity('All');

        setFilters(prev => ({

          ...prev,

          city: 'All',

          currentPage: 1

        }));

        break;

  

      case 'neighborhood':

        setSelectedNeighborhood('');

        setFilters(prev => ({

          ...prev,

          neighborhood: '',

          currentPage: 1

        }));

        break;

  

      case 'price':

        setPriceRange({ min: 0, max: 10000000 });

        setFilters(prev => ({

          ...prev,

          priceRange: { min: 0, max: 10000000 },

          currentPage: 1

        }));

        break;

  

      case 'bedrooms':

        setSelectedBedrooms('Any');

        setFilters(prev => ({

          ...prev,

          bedrooms: 'Any',

          currentPage: 1

        }));

        break;

  

      case 'bathrooms':

        setSelectedBathrooms('Any');

        setFilters(prev => ({

          ...prev,

          bathrooms: 'Any',

          currentPage: 1

        }));

        break;

  

      case 'search':

        setSearchQuery('');

        setManualSearchQuery('');

        setSearchApplied(false);

        setFilters(prev => ({

          ...prev,

          searchQuery: '',

          currentPage: 1

        }));

        break;

  

      case 'propertyType':

        setSelectedPropertyTypes([]);

        setTempPropertyTypes([]);

        setFilters(prev => ({

          ...prev,

          propertyTypes: [],

          currentPage: 1

        }));

        break;

  

      case 'style':

        setSelectedStyle('All');

        setFilters(prev => ({

          ...prev,

          style: 'All',

          currentPage: 1

        }));

        break;

  

      default:

        break;

    }

  

    // Reset current page

    setCurrentPage(1);

  

    // Update URL with remaining active filters

    const queryParams = new URLSearchParams({

      city: filterType === 'city' ? 'All' : selectedCity,

      minPrice: filterType === 'price' ? 0 : priceRange.min,

      maxPrice: filterType === 'price' ? 10000000 : priceRange.max,

      bedrooms: filterType === 'bedrooms' ? 'Any' : selectedBedrooms,

      bathrooms: filterType === 'bathrooms' ? 'Any' : selectedBathrooms,

      style: filterType === 'style' ? 'All' : selectedStyle,

      search: filterType === 'search' ? '' : searchQuery,

      property_info: filterType === 'propertyType' ? '' : 

        (selectedPropertyTypes.length > 0 ? selectedPropertyTypes.join(',') : ''),

      page: 1

    }).toString();

  

    window.history.pushState(null, '', `/listings?${queryParams}`);

  };



  const handleImageClick = (e, listing) => {

    e.stopPropagation();

    

    const shouldBlur = listing.search_type !== 'IDX' && !user;

  

    if (shouldBlur) {

      // Save current filters and page in the URL

      const queryParams = new URLSearchParams({

        city: selectedCity,

        minPrice: priceRange.min,

        maxPrice: priceRange.max,

        bedrooms: selectedBedrooms,

        bathrooms: selectedBathrooms,

        search: searchQuery,

        page: currentPage

      }).toString();

  

      // Redirect to login with the correct base URL and query parameters

      const loginUrl = `/login?redirect=${encodeURIComponent(`/listings?${queryParams}`)}`;

      

      window.location.href = loginUrl;  // Redirect the user

      return;

    }

  

    setSelectedListing(listing);

  };


  const handleSearchKeyPress = (e) => {

    if (e.key === 'Enter') {

      e.preventDefault(); // Prevent form submission

      handleSearch();

    }

  };

  



  

  const handleCityInputChange = (e) => {

    const input = e.target.value;

    setManualSearchQuery(input);

  

    if (searchTimeoutRef.current) {

      clearTimeout(searchTimeoutRef.current);

    }

  

    setFilteredCities([]);

  

    if (input.length >= 2) {

      searchTimeoutRef.current = setTimeout(async () => {

        try {

          const suggestions = [];

          const cleanedInput = input.trim().toUpperCase();

          const isMLSFormat = /^[A-Z]\d{2,}$/.test(cleanedInput);

  

          if (isMLSFormat) {

            const mlsResponse = await axios.get('/listings', {

              params: {

                ml_num: cleanedInput,

                limit: 5

              }

            });

  

            // Clean up MLS suggestions to show only address and city

            const mlsSuggestions = mlsResponse.data.map(listing => {

              // Construct the base address with unit number if it exists

              const baseAddress = `${listing.address}${listing.apt_num ? ` #${listing.apt_num}` : ''}`;

              return {

                // Only show address and municipality once

                label: `${baseAddress}, ${listing.municipality}`,

                type: 'MLS',

                mlsNumber: listing.ml_num,

                listingId: listing.ml_num

              };

            });

  

            suggestions.push(...mlsSuggestions);

          }

  

          // Rest of your existing suggestion logic for cities, neighborhoods, etc.

          if (suggestions.length < 5) {

            const matchedCities = initialCities

              .filter((city) => city.toLowerCase().startsWith(input.toLowerCase()))

              .map((city) => ({

                label: `${city}, Ontario`,

                type: 'City',

              }));

  

            const matchedNeighborhoods = neighborhoods

              .filter((neighborhood) => neighborhood.name.toLowerCase().startsWith(input.toLowerCase()))

              .map((neighborhood) => ({

                label: `${neighborhood.name}, ${neighborhood.city}, Ontario`,

                type: 'Neighborhood',

              }));

  

            suggestions.push(...matchedCities, ...matchedNeighborhoods);

  

            if (suggestions.length < 5 && !isMLSFormat) {

              const addressResponse = await axios.get('/listings', {

                params: {

                  addressSearch: input,

                  limit: 5

                }

              });

  

              const matchedAddresses = addressResponse.data

                .filter((listing) => listing.address.toLowerCase().includes(input.toLowerCase()))

                .map(listing => ({

                  label: `${listing.address}${listing.apt_num ? ` #${listing.apt_num}` : ''}, ${listing.municipality}, Ontario`,

                  type: 'Address',

                  listingId: listing.ml_num,

                }));

  

              suggestions.push(...matchedAddresses);

            }

          }

  

          setFilteredCities(suggestions.slice(0, 5));

        } catch (error) {

          console.error('Error fetching suggestions:', error);

        }

      }, 300);

    }

  };



  



// In `handleSearch`, update `searchQuery` and remove direct calls to `fetchPageListings` and `fetchClusterListings`

// Replace the existing handleSearch function

const handleSearch = async () => {

  if (!manualSearchQuery.trim()) return;


  const cleanedQuery = manualSearchQuery.trim().toUpperCase();

  // Add detailed logging for MLS detection

  console.log('Input analysis:', {

    original: manualSearchQuery,

    cleaned: cleanedQuery,

    matchesPattern: /^[A-Z]\d{7,}$/.test(cleanedQuery),

    startsWithLetter: /^[A-Z]/.test(cleanedQuery),

    length: cleanedQuery.length

  });


  // Check if it's MLS format (letter followed by numbers, at least 8 chars)

  const isMlsNumber = /^[A-Z]\d{7,}$/.test(cleanedQuery);


  const params = {

    limit: listingsPerPage,

    offset: 0

  };


  // Log what kind of search we're doing

  console.log('Search type:', isMlsNumber ? 'MLS' : 'Address');

  

  if (isMlsNumber) {

    // For MLS search, only send ml_num

    console.log('Sending MLS search params:', {

      ml_num: cleanedQuery,

    });

    params.ml_num = cleanedQuery;

  } else {

    // For address search

    console.log('Sending address search params:', {

      addressSearch: manualSearchQuery,

    });

    params.addressSearch = manualSearchQuery;

    params.minPrice = priceRange.min;

    params.maxPrice = priceRange.max;

  }


  // Log final params being sent to server

  console.log('Final request params:', params);


  try {

    const response = await axios.get('/listings', { params });

    // ...rest of the function stays the same...

  } catch (error) {

    console.error('Search error:', error);

    setError('Failed to perform search');

  }

};


  

  const renderImage = (listing) => {

    const mainImage = listing.photo_urls.split(',')[0];

  

    // Determine if we should blur the image based on search_type

    const shouldBlur = listing.search_type !== 'IDX' && !user;

  

    return (

      <div className='image-container'>

        <img

          src={mainImage}

          alt="Listing"

          className={`listing-image ${shouldBlur ? 'blurred' : ''}`}

          onClick={(e) => {

            if (!shouldBlur) {

              handleImageClick(e, listing);

            }

          }}

        />

        {shouldBlur && (

          <div className="login-button-overlay">

            <a

              href="#"

              className="login-button"

              onClick={(e) => {

                e.preventDefault();


  

                // Create URLSearchParams for the current filters, page, and MLS number

                const queryParams = new URLSearchParams({

                  city: selectedCity,

                  minPrice: priceRange.min,

                  maxPrice: priceRange.max,

                  bedrooms: selectedBedrooms,

                  bathrooms: selectedBathrooms,

                  search: searchQuery,

                  page: currentPage,

                  ml_num: listing.ml_num  // Append the MLS number correctly

                });

  

                // Convert queryParams to a string for the URL

                const queryString = queryParams.toString();              

  

                // Build the login URL and ensure encoding is correct

                const loginUrl = `/login?redirect=${encodeURIComponent(`/listings?${queryString}`)}`;


  

                // Redirect to login page with the constructed URL

                window.location.href = loginUrl;

              }}

            >

              Login to view details

            </a>

          </div>

        )}

      </div>

    );

  };

  

  return (

    <div className='listing-search-container'>

      <SEO

        title="Search Listings | Modern Solution Realty"

        description="Browse through property listings to find your ideal home. Use filters to refine your search by city, price, bedrooms, and bathrooms."

      />

      {error && (

        <div className='error-message'>

          Error fetching listings data: {error}

        </div>

      )}

    

      <div className='filter-bar'>

        <div className='filter-item price-range-filter'>

          <label>Price Range:</label>

          <div className="price-inputs">

          <input

  type="number"

  placeholder="Min"

  value={priceRange.min}

  onChange={(e) => handleFilterChange(setPriceRange, 'priceRange')({ 

    ...priceRange, 

    min: e.target.value 

  })}

  className="price-input"

/>

            <span> - </span>

            <input

              type="number"

              placeholder="Max"

              value={priceRange.max}

              onChange={(e) => handleFilterChange(setPriceRange, 'priceRange')({ 

                ...priceRange, 

                max: e.target.value 

              })}

              className="price-input"

            />

          </div>

        </div>

        <div className='filter-item'>

          <label>City:</label>

          <select

  value={selectedCity}

  onChange={(e) => handleFilterChange(setSelectedCity, 'city')(e.target.value)}

  className='city-select'

>

            <option value='All'>Select City</option>

            {uniqueCities.map((city, index) => (

              <option key={index} value={city}>

                {city}

              </option>

            ))}

          </select>

        </div>


        {/* Add this after the City filter div */}

        <div className='filter-item property-type-filter'>

  <label>Property Type</label>

  <div className="dropdown-container">

    <button 

      className="dropdown-toggle" 

      onClick={(e) => {

        e.stopPropagation();

        setIsPropertyTypeOpen(!isPropertyTypeOpen);

        // Initialize temp state with current selection when opening dropdown

        setTempPropertyTypes(selectedPropertyTypes);

      }}

    >

      {selectedPropertyTypes.length === 2 ? 'All Types' :

       selectedPropertyTypes.includes('Residential') ? 'House' :

       selectedPropertyTypes.includes('Condo') ? 'Condo' : 'Select Type'}

      <span style={{ float: 'right' }}>▼</span>

    </button>

    

    {isPropertyTypeOpen && (

      <div className="dropdown-menu">

      {/* House Checkbox */}

      <label className="checkbox-label">

        <input

          type="checkbox"

          checked={tempPropertyTypes.includes('House')}

          onChange={() => {

            setTempPropertyTypes((prev) =>

              prev.includes('House')

                ? prev.filter((type) => type !== 'House')

                : [...prev, 'House']

            );

          }}

        />

        House

      </label>

    

      {/* Condo Checkbox */}

      <label className="checkbox-label">

        <input

          type="checkbox"

          checked={tempPropertyTypes.includes('Condo')}

          onChange={() => {

            setTempPropertyTypes((prev) =>

              prev.includes('Condo')

                ? prev.filter((type) => type !== 'Condo')

                : [...prev, 'Condo']

            );

          }}

        />

        Condo

      </label>

    

      {/* Townhouse Checkbox */}

      <label className="checkbox-label">

        <input

          type="checkbox"

          checked={tempPropertyTypes.includes('Townhouse')}

          onChange={() => {

            setTempPropertyTypes((prev) =>

              prev.includes('Townhouse')

                ? prev.filter((type) => type !== 'Townhouse')

                : [...prev, 'Townhouse']

            );

          }}

        />

        Townhouse

      </label>

    

      

<button 

  className="apply-button"

  onClick={async () => {

    setSelectedPropertyTypes(tempPropertyTypes);

    setIsPropertyTypeOpen(false);

    setCurrentPage(1);

    setLoading(true);


    try {

      const selectedCodes = tempPropertyTypes

        .flatMap((type) => PROPERTY_INFO_CODES[type] || [])

        .join(',');



      // Fetch listings

      const pageResponse = await axios.get('/listings', {

        params: {

          limit: listingsPerPage,

          offset: 0,

          city: selectedCity !== 'All' ? selectedCity : null,

          community: selectedNeighborhood !== 'All' ? selectedNeighborhood : null,

          bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,

          bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,

          style: selectedStyle !== 'All' ? selectedStyle : null,

          minPrice: priceRange.min,

          maxPrice: priceRange.max,

          property_info: selectedCodes || null

        },

      });


      setFilteredListings(pageResponse.data);


      // Update URL with property_info instead of property_search

      const queryParams = new URLSearchParams({

        city: selectedCity,

        minPrice: priceRange.min,

        maxPrice: priceRange.max,

        bedrooms: selectedBedrooms,

        bathrooms: selectedBathrooms,

        style: selectedStyle,

        search: searchQuery,

        property_info: selectedCodes || '',

        page: 1

      }).toString();

      window.history.pushState(null, '', `/listings?${queryParams}`);


    } catch (error) {

      console.error('Error applying property type filter:', error);

    } finally {

      setLoading(false);

    }

  }}

>

  Apply

</button>

    </div>

    

    )}

  </div>

</div>


        

        <div className='filter-item'>

          <label>Bedrooms:</label>

          <select

  value={selectedBedrooms}

  onChange={(e) => handleFilterChange(setSelectedBedrooms, 'bedrooms')(e.target.value)}

  className='bedrooms-select'

>

            <option value='Any'>Any</option>

            {initialBedrooms.map((bedrooms, index) => (

              <option key={index} value={bedrooms}>

                {bedrooms}

              </option>

            ))}

          </select>

        </div>

        

 


        <div className='filter-item'>

          <label>Bathrooms:</label>

          <select

  value={selectedBathrooms}

  onChange={(e) => handleFilterChange(setSelectedBathrooms, 'bathrooms')(e.target.value)}

  className='bathrooms-select'

>

            <option value='Any'>Any</option>

            {initialBathrooms.map((bathrooms, index) => (

              <option key={index} value={bathrooms}>

                {bathrooms}

              </option>

            ))}

          </select>

        </div>

        

        

        <div className='filter-item search-filter'>

  <label>Search Address/MLS #:</label>

  <div ref={desktopDropdownRef} className="search-input-container">

    <input

      type='text'

      value={manualSearchQuery}

      onChange={handleCityInputChange}

      onKeyPress={handleSearchKeyPress}

      placeholder='Address, City, MLS #'

      className='search-input'

    />

    <button onClick={handleSearch} className="search-button">

      <FaSearch />

    </button>


    {filteredCities.length > 0 && (

      <ul className="city-suggestions">

        {filteredCities.map((item, index) => {

          // For MLS type, display the label directly without highlighting

          if (item.type === 'MLS') {

            return (

              <li key={index} onClick={() => handleCitySelection(item)}>

                <div className="suggestion-item">

                  <span className="city-name">{item.label}</span>

                  <span className="city-label">{item.type}</span>

                </div>

              </li>

            );

          }


          // For other types, keep the text highlighting

          const startIndex = item.label.toLowerCase().indexOf(manualSearchQuery.toLowerCase());

          const endIndex = startIndex + manualSearchQuery.length;


          const beforeMatch = item.label.slice(0, startIndex);

          const matchText = item.label.slice(startIndex, endIndex);

          const afterMatch = item.label.slice(endIndex);


          return (

            <li key={index} onClick={() => handleCitySelection(item)}>

              <div className="suggestion-item">

                <span className="city-name">

                  {beforeMatch}

                  <strong>{matchText}</strong>

                  {afterMatch}

                </span>

                <span className="city-label">{item.type}</span>

              </div>

            </li>

          );

        })}

      </ul>

    )}

  </div>

</div>



      </div>

      <div className='filter-item search-filter mobile-only'>

  <label>Search Address/MLS #:</label>

  <div ref={mobileDropdownRef} className="search-input-container">

    <input

      type='text'

      value={manualSearchQuery}

      onChange={handleCityInputChange}

      onKeyPress={handleSearchKeyPress}

      placeholder='Address, City, Neighbourhood, MLS #'

      className='search-input'

    />

    <button onClick={handleSearch} className="search-button">

      <FaSearch />

    </button>


    {filteredCities.length > 0 && (

      <ul className="city-suggestions">

        {filteredCities.map((item, index) => {

          // For MLS type, display the label directly without highlighting

          if (item.type === 'MLS') {

            return (

              <li key={index} onClick={() => handleCitySelection(item)}>

                <div className="suggestion-item">

                  <span className="city-name">{item.label}</span>

                  <span className="city-label">{item.type}</span>

                </div>

              </li>

            );

          }


          // For other types, keep the text highlighting

          const startIndex = item.label.toLowerCase().indexOf(manualSearchQuery.toLowerCase());

          const endIndex = startIndex + manualSearchQuery.length;


          const beforeMatch = item.label.slice(0, startIndex);

          const matchText = item.label.slice(startIndex, endIndex);

          const afterMatch = item.label.slice(endIndex);


          return (

            <li key={index} onClick={() => handleCitySelection(item)}>

              <div className="suggestion-item">

                <span className="city-name">

                  {beforeMatch}

                  <strong>{matchText}</strong>

                  {afterMatch}

                </span>

                <span className="city-label">{item.type}</span>

              </div>

            </li>

          );

        })}

      </ul>

    )}

  </div>

</div>  

      

<div className='mobile-filter-section'>

  <button className='filter-toggle-btn' onClick={toggleMobileFilters}>

    Filter Your Search →

  </button>

  {showMobileFilters && (

    <div className='mobile-filter-options'>

      <div className='filter-item price-range-filter'>

        <label>Price Range:</label>

        <div className="price-inputs">

        <input

  type="number"

  placeholder="Min"

  value={priceRange.min}

  onChange={(e) => handleFilterChange(setPriceRange, 'priceRange')({ 

    ...priceRange, 

    min: e.target.value 

  })}

  className="price-input"

/>

            <span> - </span>

            <input

              type="number"

              placeholder="Max"

              value={priceRange.max}

              onChange={(e) => handleFilterChange(setPriceRange, 'priceRange')({ 

                ...priceRange, 

                max: e.target.value 

              })}

              className="price-input"

            />

        </div>

      </div>


     

      

      <div className='filter-item'>

        <label>City:</label>

        <select

  value={selectedCity}

  onChange={(e) => handleFilterChange(setSelectedCity, 'city')(e.target.value)}

  className='city-select'

>

          <option value='All'>Select City</option>

          {uniqueCities.map((city, index) => (

            <option key={index} value={city}>

              {city}

            </option>

          ))}

        </select>

      </div>


      {/* Add this after the City filter div */}

      <div className='filter-item property-type-filter'>

  <label>Property Type</label>

  <div className="dropdown-container">

    <button 

      className="dropdown-toggle" 

      onClick={(e) => {

        e.stopPropagation();

        setIsPropertyTypeOpen(!isPropertyTypeOpen);

        // Initialize temp state with current selection when opening dropdown

        setTempPropertyTypes(selectedPropertyTypes);

      }}

    >

      {selectedPropertyTypes.length === 2 ? 'All Types' :

       selectedPropertyTypes.includes('Residential') ? 'House' :

       selectedPropertyTypes.includes('Condo') ? 'Condo' : 'Select Type'}

      <span style={{ float: 'right' }}>▼</span>

    </button>

    

    {isPropertyTypeOpen && (

      <div className="dropdown-menu">

      {/* House Checkbox */}

      <label className="checkbox-label">

        <input

          type="checkbox"

          checked={tempPropertyTypes.includes('House')}

          onChange={() => {

            setTempPropertyTypes((prev) =>

              prev.includes('House')

                ? prev.filter((type) => type !== 'House')

                : [...prev, 'House']

            );

          }}

        />

        House

      </label>

    

      {/* Condo Checkbox */}

      <label className="checkbox-label">

        <input

          type="checkbox"

          checked={tempPropertyTypes.includes('Condo')}

          onChange={() => {

            setTempPropertyTypes((prev) =>

              prev.includes('Condo')

                ? prev.filter((type) => type !== 'Condo')

                : [...prev, 'Condo']

            );

          }}

        />

        Condo

      </label>

    

      {/* Townhouse Checkbox */}

      <label className="checkbox-label">

        <input

          type="checkbox"

          checked={tempPropertyTypes.includes('Townhouse')}

          onChange={() => {

            setTempPropertyTypes((prev) =>

              prev.includes('Townhouse')

                ? prev.filter((type) => type !== 'Townhouse')

                : [...prev, 'Townhouse']

            );

          }}

        />

        Townhouse

      </label>

    

      

<button 

  className="apply-button"

  onClick={async () => {

    setSelectedPropertyTypes(tempPropertyTypes);

    setIsPropertyTypeOpen(false);

    setCurrentPage(1);

  

    const selectedCodes = tempPropertyTypes

      .flatMap((type) => PROPERTY_INFO_CODES[type] || [])

      .join(',');

  

    // Update filters state instead of making direct API call

    setFilters(prev => ({

      ...prev,

      propertyTypes: tempPropertyTypes,

      currentPage: 1

    }));

  

    // Update URL with all current filters

    const queryParams = new URLSearchParams({

      city: selectedCity,

      minPrice: priceRange.min,

      maxPrice: priceRange.max,

      bedrooms: selectedBedrooms,

      bathrooms: selectedBathrooms,

      style: selectedStyle,

      search: searchQuery,

      property_info: selectedCodes || '',

      page: 1

    }).toString();

    window.history.pushState(null, '', `/listings?${queryParams}`);

  }}>

  Apply

</button>

    </div>

    )}

  </div>

</div>


      <div className='filter-item'>

        <label>Bedrooms:</label>

        <select

  value={selectedBedrooms}

  onChange={(e) => handleFilterChange(setSelectedBedrooms, 'bedrooms')(e.target.value)}

  className='bedrooms-select'

>

          <option value='Any'>Any</option>

          {initialBedrooms.map((bedrooms, index) => (

            <option key={index} value={bedrooms}>

              {bedrooms}

            </option>

          ))}

        </select>

      </div>


      <div className='filter-item'>

        <label>Bathrooms:</label>

        <select

  value={selectedBathrooms}

  onChange={(e) => handleFilterChange(setSelectedBathrooms, 'bathrooms')(e.target.value)}

  className='bathrooms-select'

>

          <option value='Any'>Any</option>

          {initialBathrooms.map((bathrooms, index) => (

            <option key={index} value={bathrooms}>

              {bathrooms}

            </option>

          ))}

        </select>

      </div>

    </div>

  )}

</div>



      <div className='applied-filters-bar'>

  {selectedCity !== 'All' && selectedCity !== '' && (

    <button

      onClick={() => removeFilter('city')}

      className='filter-button'

    >

      City: {selectedCity} <span className='remove-filter'>X</span>

    </button>

  )}


{selectedStyle !== 'All' && (

        <button

          onClick={() => removeFilter('style')}

          className='filter-button'

        >

          Style: {selectedStyle} <span className='remove-filter'>X</span>

        </button>

      )}

  {selectedNeighborhood !== '' && selectedNeighborhood !== 'All' && (

    <button

      onClick={() => removeFilter('neighborhood')}

      className='filter-button'

    >

      Neighborhood: {selectedNeighborhood} <span className='remove-filter'>X</span>

    </button>

  )}

  {(priceRange.min !== 0 || priceRange.max !== 10000000) && (

    <button

      onClick={() => removeFilter('price')}

      className='filter-button'

    >

      Price: {formatPrice(priceRange.min)} - {formatPrice(priceRange.max)}{' '}

      <span className='remove-filter'>X</span>

    </button>

  )}

  {selectedBedrooms !== 'Any' && (

    <button

      onClick={() => removeFilter('bedrooms')}

      className='filter-button'

    >

      Bedrooms: {selectedBedrooms} <span className='remove-filter'>X</span>

    </button>

  )}

  {selectedBathrooms !== 'Any' && (

    <button

      onClick={() => removeFilter('bathrooms')}

      className='filter-button'

    >

      Bathrooms: {selectedBathrooms} <span className='remove-filter'>X</span>

    </button>

  )}

  {searchApplied && manualSearchQuery && selectedCity === 'All' && selectedNeighborhood === '' && (

    <button

      onClick={() => removeFilter('search')}

      className='filter-button'

    >

      Search: {manualSearchQuery} <span className='remove-filter'>X</span>

    </button>

  )}


{selectedPropertyTypes.length > 0 && (

  <button

    onClick={() => removeFilter('propertyType')}

    className="filter-button"

  >

    Property Type: {selectedPropertyTypes.join(', ')}

    <span className="remove-filter">X</span>

  </button>

)}


  {(selectedCity !== 'All' ||

    selectedNeighborhood !== '' ||

    priceRange.min !== 0 ||

    priceRange.max !== 10000000 ||

    selectedBedrooms !== 'Any' ||

    selectedBathrooms !== 'Any' ||

    (searchApplied && manualSearchQuery && selectedCity === 'All' && selectedNeighborhood === '')) && (

    <button onClick={clearFilters} className='clear-filters-button'>

      Clear All

    </button>

  )}

</div>


      <div className='main-content'>


        <div className='listings-column'>

          <div className={`listing-grid ${isFirstLoad ? 'transition-container' : ''}`}>

            {loading ? (

              Array.from({ length: listingsPerPage }).map((_, index) => (

                <div key={index} className="listing-card loading-card">

                  {/* The loading animation is applied here */}

                </div>

              ))

            ) : filteredListings.length > 0 ? (

              filteredListings.map((listing) => {

              


                // Only blur listings that do not have search_type as 'IDX'

                const shouldBlur = listing.search_type !== 'IDX' && !user;

              

                return (

                  <div

                    key={listing.ml_num}

                    className={`listing-card ${shouldBlur ? 'blurred' : ''}`} // Apply 'blurred' class if not IDX

                    onClick={(e) => handleImageClick(e, listing)}

                    

                  >

                    {/* Render the image */}

                    {renderImage(listing)}

              

                    {/* Blur the listing content if necessary */}

                    <div className={`listing-content ${shouldBlur ? 'blurred' : ''}`}>

                      {/* The rest of your listing content goes here */}

                      <div className="listing-style-badge">

                        <span><a href="/contact">Buy and Get $5000 Rebate</a></span>

                      </div>

              

                      <div className="listing-details">

                        <div className="listing-detail-item">

                          <BedroomIcon className="icon" />

                          <span className="with-words"> {listing.bedrooms || 'N/A'}</span>

                          <span className="without-words">{listing.bedrooms || 'N/A'}</span>

                        </div>

                        <div className="listing-detail-item">

                          <BathroomIcon className="icon" />

                          <span className="with-words"> {listing.bathrooms || 'N/A'}</span>

                          <span className="without-words">{listing.bathrooms || 'N/A'}</span>

                        </div>

                        <div className="listing-detail-item">

                          SQFT: {listing.square_feet || 'N/A'}

                        </div>

                      </div>

              

                      <div className="listing-title">

                        <div className="title-community">

                          <h3 className="street-address"> {listing.address} {listing.apt_num ? `#${listing.apt_num}` : ''}</h3>

                          <p className="community">

                            {listing.community}{' '}

                            <span className="separator-dot">•</span>{' '}

                            {listing.municipality}

                          </p>

                        </div>

                        <div className="listing-price">

                          {formatPrice(listing.price)}

                        </div>

                      </div>

                    </div>

              

                    {/* Optional: Show overlay if blurred */}

                    {!user && (listing.search_type === 'NULL' || listing.search_type === 'VOW') && (

                      <div className="login-button-overlay">

                        <a href="/login" className="login-button">

                          Login to View Listing

                        </a>

                      </div>

                    )}

                  </div>

                );

              })

            ) : (

              <div>No listings found for your criteria.</div>

            )}

          </div>


          <div className='pagination'>

  <button 

    onClick={() => paginate(currentPage - 1)}

    disabled={currentPage === 1}

    className='pagination-button'

  >

    Previous

  </button>

  <button 

    onClick={() => paginate(currentPage + 1)}

    disabled={!hasMore}

    className='pagination-button'

  >

    Next

  </button>

</div>



        </div>

      </div>


      {selectedListing && (

        <>

          <div className='listing-popup-overlay' onClick={() => setSelectedListing(null)}></div>

          <div className='listing-popup'>

            <div className='banner'>

              <div className='banner-left'>

                <a href="#" className='share-link' onClick={handleShare}>

                  <span>Share</span> <FaShareAlt className='banner-icon' />

                </a>

              </div>

              <div className='banner-middle'>

                {[

                  { name: 'Overview', anchor: '#overview' },

                  { name: 'Sold/Listing History', anchor: '#listing-history' },

                  { name: 'Facts & Features', anchor: '#facts-features' },

                  { name: 'Payment Calculator', anchor: '#payment-calculator' },

                ].map((item, index) => (

                  <a key={index} href={item.anchor} className='banner-menu-item'>

                    {item.name}

                  </a>

                ))}

              </div>

              <div className='banner-right'>

                <button

                  className='popup-close-button'

                  onClick={() => setSelectedListing(null)}

                >

                  X

                </button>

              </div>

            </div>


            <div className='popup-content'>

              <ListingDetail ml_num={selectedListing.ml_num} />

            </div>

          </div>

        </>

      )}

      {showSharePopup && (

        <SharePopup

          onClose={() => setShowSharePopup(false)}

          listingUrl={currentListingUrl}

        />

      )}

      <StickyBottomBar />

    </div>

  );

};


export default Listings;
