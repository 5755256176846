import React, { useState, useEffect, useRef } from 'react';
import { FaPlay } from 'react-icons/fa';
import ListingWidget from './ListingWidget';
import thestarLogo from '../assets/images/thestar-logo-2.png';
import googleLogo from '../assets/images/google-G.png';
import { Phone, Mail, MapPin, Clock } from 'lucide-react';
import bgImage from '../assets/images/bg.webp';
import './BuyHomeLanding.css';
import AdvertisingPartners from './AdvertisingPartners';
import Footer from './Footer';
import ContactForm from './ContactForm';
import CityWidget from './CityWidget';
import townhouse from '../assets/images/townhouse.jpg';
import detached from '../assets/images/detachef home.jpg';
import condo from '../assets/images/condo.jpeg';
import PhoneButton from './PhoneButton';



const reviews = [
  {
    author: "Safraz Baksh",
    date: "A Week Ago",
    text: "I had an excellent experience working with Hannah Manesh on my recent purchase. She transformed what could have been a stressful process into a seamless and efficient one. Throughout the entire transaction, she maintained clear and consistent communication, diligently represented my best interests during negotiations, and remained in contact even beyond the closing. I highly recommend Hannah to friends and family for both buying and selling properties. Thank you, Hannah!"
  },
  {
    author: "Tyler Rich",
    date: "4 Weeks Ago",
    text: "We had a perfect experience working with Hannah at Modern Solution Realty inc. Her dedication to finding us the right place to call home was incredibly impressive. She was 100% accommodating and professional throughout the entire experience and took what could of been a stressful time and turned it into something streamline and easy. I would recommend Hannah to absolutely everyone. BEST REALTOR EVER."
  },
  {
    author: "Maddie Bautista",
    date: "3 Months Ago",
    text: "We came across Modern Solution when family recommended their services to us. Ryan was our realtor -- who we strongly recommend! My partner and I were first time homebuyers and Ryan facilitated a frictionless, professional, efficient, and fun process. He was so easy to get in touch with when we had any questions, and was a great negotiator as well. A gesture that my partner and I really appreciated was when Ryan noticed that we liked a piece of furniture in the home that we bought, he sent a link of that product to us in case we wanted to buy our own. It really speaks to Ryan's attention to detail and thoughtfulness. If you're a FTHB, do get in touch with Modern Solution and work with Ryan!"
  },
  {
    author: "Ata Uğur",
    date: "6 Months Ago",
    text: "Ryan was crucial in helping me secure my new home! Hard working and determined guy. Went to numerous listings with me to make sure we got the right home. Couldn’t recommend him enough!"
  },
  {
    author: "Miss Nikbe",
    date: "2 Months Ago",
    text: "This was my first time working with an agent to sell a property. Neha Verman made the whole process easy and convenient for me. She was very professional and knowledgeable about the process and these skills make her a desirable contact for any buyer or seller."
  }
];

const counters = [
  { icon: require('../assets/images/payment-method.png'), value: '$5,000 Cashback', text: 'Guaranteed (No tricks, no fine print!)' },
  { icon: require('../assets/images/conversation.png'), value: 'Expert Negotiators', text: 'We fight to get you the best deal.' },
  { icon: require('../assets/images/agreement.png'), value: 'Full-Time Realtors', text: 'Dedicated support, every step of the way.' },
  { icon: require('../assets/images/analytics.png'), value: 'Local Market Experts', text: 'From Toronto to Hamilton, Kitchener & beyond.' },
  { icon: require('../assets/images/exclusive.png'), value: 'Exclusive Access', text: 'MLS listings, new builds & power of sale homes.' },
];

const slides = [
  {
    image: require('../assets/images/1.webp'),
    title: 'A Home Evaluation and Comparative Market Analysis',
    text: 'Our full-time local real estate agents will assess your home, providing a free and obligation-free written appraisal with the current market value.',
  },
  {
    image: require('../assets/images/2.webp'),
    title: 'Marketing Strategy',
    text: 'Tailoring the strategy to your property, our agents will analyze its unique features and devise a targeted marketing approach while maintaining our promise of listing your property for 1%.',
  },
  {
    image: require('../assets/images/3.webp'),
    title: 'Showing Management',
    text: 'At Modern Solution Realty, our office manages all of your showings and confirms times that work around your busy schedule. Tip: If you want to sell your property, you should allow access to as many people through the property to gain maximum exposure.',
  },
  {
    image: require('../assets/images/4.webp'),
    title: 'The Implementation of Marketing Tactics',
    text: 'We adopt a hands-on approach to every 1% listing, utilizing a range of techniques and services to secure the highest price for your property in the shortest time possible.',
  },
  {
    image: require('../assets/images/5.webp'),
    title: 'Negotiating Offers',
    text: "You won't be alone on deciding what to do when you receive an offer. Our team of agents are highly trained negotiators and will be with you every step of the way, in your corner to get the right price, terms and conditions – in short, to get the right deal for you!",
  },
  {
    image: require('../assets/images/6.webp'),
    title: 'Full MLS Exposure',
    text: 'Your property is seen through every avenue. Listed on all regional boards, the Toronto Regional Real Estate Board, House Sigma, Realtor.ca, and all platforms.',
  },
  {
    image: require('../assets/images/7.webp'),
    title: 'Sale and Closing',
    text: 'We pride ourselves on offering top-notch service from start to finish. Our realtors are available 24/7 to advise and address all your concerns. Our commitment to a great, hassle-free selling experience remains unchanged, even with your 1% listing.',
  },
  {
    image: require('../assets/images/8.webp'),
    title: 'Call Modern Solution',
    text: "Call Modern Solution Realty if you have any questions about buying or selling a home. We'd be happy to help with any of your real estate-related matters.",
  },
];

const BuyHomeLanding = () => {
  const [activeReview, setActiveReview] = useState(0);
  const [transitionClass, setTransitionClass] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  const transitionDuration = 300;

  const changeReview = (newIndex) => {
    if (transitionClass) return;
    setTransitionClass("fade-out");
    setTimeout(() => {
      setActiveReview(newIndex);
      setTransitionClass("fade-in");
      setTimeout(() => {
        setTransitionClass("");
      }, transitionDuration);
    }, transitionDuration);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      changeReview(activeReview === reviews.length - 1 ? 0 : activeReview + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeReview, transitionClass]);

  const handleNext = () => {
    changeReview(activeReview === reviews.length - 1 ? 0 : activeReview + 1);
  };

  const handlePrev = () => {
    changeReview(activeReview === 0 ? reviews.length - 1 : activeReview - 1);
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  return (
    <div className="buy-my-home-page page-container">
      <div className="background-decor decor-top-left"></div>
      <div className="background-decor decor-bottom-right"></div>

      <div className="buy-home-landing-content">
        <div className="buy-home-landing-left">
          <div className="buy-home-landing-badge">Serving All of Southern Ontario!</div>
          <h1 className="buy-home-landing-title">
          Find Your Dream Home in Southern Ontario & Get <span className="brand-color">$5,000</span> Cashback
          </h1>
          <p className="buy-home-landing-subtitle">
          Buying a Home? Get Expert Guidance + $5,000 in Your Pocket. At ModernSolution Realty, we make homebuying simple, rewarding, and stress-free. 

          
          </p>
          <a href="/contact">
          <button className="buy-home-landing-button" >
            
Connect With Our Experienced Agents Today And Claim Your $5000 Cash Back!
          </button></a>
          
        </div>

        <div className="buy-home-landing-right">
          <div className="reviews-container">
            <div className="reviews-background"></div>
            <div className={`review-card desktop-only ${transitionClass}`}>
              <div className="review-progress">
                {reviews.map((_, index) => (
                  <div 
                    key={index}
                    className={`progress-dot ${index === activeReview ? 'active' : ''}`}
                  />
                ))}
              </div>
              
              <div className="review-header">
                <img src={googleLogo} alt="Google" className="google-logo" />
                <div className="review-rating">
                  <div className="stars">
                    <span className="star">★</span>
                    <span className="star">★</span>
                    <span className="star">★</span>
                    <span className="star">★</span>
                    <span className="star">★</span>
                  </div>
                  <span className="rating-text">4.8 stars (233 total reviews)</span>
                </div>
              </div>

              <div className="review-content">
                <div className="review-author">{reviews[activeReview].author}</div>
                <div className="review-date">{reviews[activeReview].date}</div>
                <p className="review-text">{truncateText(reviews[activeReview].text, 300)}</p>
              </div>
                <div className="read-more-reviews">

                  <a href="https://g.co/kgs/cEEnYUg">Read All Reviews</a>

                </div>
              <div className="review-navigation">
                <button onClick={handlePrev} className="nav-button prev">←</button>
                <button onClick={handleNext} className="nav-button next">→</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Review Card */}
      <div className={`review-card mobile-only ${transitionClass}`}>
        <div className="review-header">
          <img src={googleLogo} alt="Google" className="google-logo" />
          <div className="review-rating">
            <div className="stars">
              <span className="star">★</span>
              <span className="star">★</span>
              <span className="star">★</span>
              <span className="star">★</span>
              <span className="star">★</span>
            </div>
            <span className="rating-text">4.8 stars (233 total reviews)</span>
          </div>
        </div>

        <div className="review-content">
          <div className="review-author">{reviews[activeReview].author}</div>
          <div className="review-date">{reviews[activeReview].date}</div>
          <p className="review-text">{truncateText(reviews[activeReview].text, 200)}</p>
        </div>

        <div className="read-more-reviews">

                  <a href="https://g.co/kgs/cEEnYUg">Read All Reviews</a>

                </div>

        <div className="review-navigation">
          <button onClick={handlePrev} className="nav-button prev">←</button>
          <button onClick={handleNext} className="nav-button next">→</button>
        </div>
      </div>

      {/* Results Counter Section */}
      <div className="counter-section">
        <h2>When you buy with us, you get:</h2>
        <div className="counter-row">
          {counters.slice(0, 3).map((counter, index) => (
            <div key={index} className="counter-box">
              <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
              <h3>{counter.value}</h3>
              <p>{counter.text}</p>
            </div>
          ))}
        </div>
        <div className="counter-row">
          {counters.slice(3, 5).map((counter, index) => (
            <div key={index} className="counter-box">
              <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
              <h3>{counter.value}</h3>
              <p>{counter.text}</p>
            </div>
          ))}
        </div>
      </div>
          <div className='steps-section'>
      <div className="numbered-steps"> 
        <div className="left-steps"><h2>We’ve paid over <b>$1,000,000</b> in cashback! Ready to claim yours?</h2> <a href="/contact">
          <button className="buy-home-landing-button" >
          Don’t Miss Out – Claim Your $5,000 Cashback Now!
          </button></a></div>
          <div className="right-steps">
          <div className="steps-grid">
          <a href="/listings?city=All&minPrice=0&maxPrice=10000000&bedrooms=Any&bathrooms=Any&style=All&search=&property_info=.6.%2C.D.%2C.F.%2C.G.%2C.J.%2C.K.%2C.L.%2C.M.%2C.N.%2C.R.%2C.S.%2C.X.&page=1" className="steps-box">
  <img src={detached} alt="Detached Homes For Sale in Ontario" className="steps-photo" />
  <p>Detached Homes <br />For Sale</p>
</a>
<a href="/listings?city=All&minPrice=0&maxPrice=10000000&bedrooms=Any&bathrooms=Any&style=All&search=&property_info=.2.%2C.3.%2C.4.%2C.C.%2C.E.%2C.H.%2C.P.%2C.W.%2C.Z.&page=1" className="steps-box">
  <img src={condo} alt="Condos For Sale in Ontario" className="steps-photo" />
  <p>Condos <br />For Sale</p>
</a>
<a href="/listings?city=All&minPrice=0&maxPrice=10000000&bedrooms=Any&bathrooms=Any&style=All&search=&property_info=.A.%2C.T.&page=1" className="steps-box">
  <img src={townhouse} alt="Townhouses For Sale in Ontario" className="steps-photo" />
  <p>Townhouses <br />For Sale</p>
</a>
</div>
</div>
          
      </div>
      </div>
      


<div className="contact-section">
<h2>Fill Out The Form and Get Started Today!</h2>
        <div className="contact-grid">
          {/* Contact Information Box */}
          <div className="contact-info-box">
            <h2>Contact Information</h2>
            <div className="contact-divider"></div>
            
            
            <a href="tel:9058975000" className="contact-item">
              <Phone className="contact-icon" />
              <span>(905) 897-5000</span>
            </a>
            
            <a href="/contact" className="contact-item">
              <Mail className="contact-icon" />
              <span>info@modernsolution.ca</span>
            </a>

            <a href="#" className="contact-item">
  <Clock className="contact-icon" />
  <span>Open from 9:00 AM - 5:00 PM</span>
</a>
            
            <a 
              href="https://www.google.com/maps/search/?api=1&query=3466+Mavis+Rd+Unit+1,+Mississauga,+ON+L5C+1T8" 
              target="_blank" 
              rel="noopener noreferrer"
              className="contact-item"
            >
              <MapPin className="contact-icon" />
              <span>3466 Mavis Rd Unit 1, Mississauga, ON L5C 1T8</span>
            </a>
          </div>

          {/* Contact Form */}
          <div className="contact-form-container">
          <h2>Send Us A Message</h2>
          <div className="contact-divider"></div>
            <ContactForm />
          </div>
        </div>
      </div>

      <div className="video-section">
        <h2>Watch Our Featured Video Now on CP24!</h2>
        </div>

        
      {/* Video Section */}
      <div className="video-container">
        
        {!isVideoPlaying && (
          <div className="unique-play-button" onClick={handlePlayVideo}>
            <FaPlay className="unique-play-icon" />
          </div>
        )}
        <video
          ref={videoRef}
          width="100%"
          height="auto"
          controls
          playsInline={true}
          preload="metadata"
          poster={require('../assets/images/video-poster-buy.png')}
          onPlay={() => setIsVideoPlaying(true)}
          onPause={() => setIsVideoPlaying(false)}
        >
          <source src={require('../assets/videos/msr-buying-page.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      

<CityWidget />
      {/* Featured Listings Section */}
      <h2 className="featured-listings-heading">Modern Solution Listings</h2>
      <ListingWidget />

      {/* Toronto Star Section */}
      <div className="toronto-star-wrapper">
        <div className="toronto-star-container">
          <div className="home-page-toronto-star">
            <img src={thestarLogo} alt="The Star Logo" className="thestar-logo" />
            <h2 className="toronto-star-heading">Modern Solution Featured in Toronto Star</h2>
            <p className="toronto-star-paragraph">
              In a real estate world where outdated commission rates have long been the norm,
              Modern Solution Realty Inc. is proud to offer a refreshing and cost-effective
              alternative.
              
              Traditional commission structures, often hovering around five to six per cent, have
              burdened home sellers with exorbitant fees. For instance, selling a $1,000,000 home with
              a standard five per cent commission could cost a staggering $50,000 — an expense that
              seems increasingly unreasonable in today's dynamic market.
            </p>
            <a
              href="https://www.thestar.com/sponsored-sections/a-modern-solution-approach-to-real-estate-with-one-per-cent-commission/article_1ca3e32e-6eec-11ef-84ea-53f224f162c8.html"
              target="_blank"
              rel="noopener noreferrer"
              className="read-more-link"
            >
              Read More
            </a>
          </div>
          <div className="toronto-star-image-column">
            <img 
              src={require('../assets/images/thestar-office.webp')} 
              alt="The Star Office" 
              className="toronto-star-image"
            />
          </div>
        </div>
      </div>
      <PhoneButton />
      <Footer />
    </div>
  );
};

export default BuyHomeLanding;