import React, { useState, useEffect, useRef } from 'react';
import { FaPlay } from 'react-icons/fa';
import ListingWidget from './ListingWidget';
import thestarLogo from '../assets/images/thestar-logo-2.png';
import googleLogo from '../assets/images/google-G.png';
import { Phone, Mail, MapPin, Clock } from 'lucide-react';
import bgImage from '../assets/images/bg.webp';
import './SellHomeLanding.css';
import AdvertisingPartners from './AdvertisingPartners';
import Footer from './Footer';
import ContactForm from './ContactForm';
import CityWidget from './CityWidget';
import CommissionCalculator from './CommissionCalculator';
import PhoneButton from './PhoneButton';


const reviews = [
  {
    author: "Safraz Baksh",
    date: "5 Days Ago",
    text: "I had an excellent experience working with Hannah Manesh on my recent purchase. She transformed what could have been a stressful process into a seamless and efficient one. Throughout the entire transaction, she maintained clear and consistent communication, diligently represented my best interests during negotiations, and remained in contact even beyond the closing. I highly recommend Hannah to friends and family for both buying and selling properties. Thank you, Hannah!"
  },
  {
    author: "Yejin Kim",
    date: "A Week Ago",
    text: "I had a great experience with Ryan Harkins. He has been patient and delightful in helping me with listings for both lease and sale and provided his honest opinions on each listing, which helped me tremendously. Also, he guided me through the whole offer process that I wasn't familiar with in an easy and clear manner."
  },
  {
    author: "Tyler Rich",
    date: "3 Weeks Ago",
    text: "We had a perfect experience working with Hannah at Modern Solution Realty inc. Her dedication to finding us the right place to call home was incredibly impressive. She was 100% accommodating and professional throughout the entire experience and took what could of been a stressful time and turned it into something streamline and easy. I would recommend Hannah to absolutely everyone. BEST REALTOR EVER."
  },
  {
    author: "Olivier S",
    date: "A Week Ago",
    text: "Selling a property is a journey. That journey can be long or short depending on many factors. One factor that everyone should try to get right is finding the right realtor. Not with the expectation that they would make miracles happen but that they would listen, ask questions, provide analysis, make suggestions and share professional advice to make the selling process a successful one. My journey was difficult until I found Neha. After being on the market for 4 months, Neha was the breath of fresh air I was looking for. Two weeks after meeting her and putting a plan in motion, my condo was sold!!! Thank you so much Neha. I look forward to working with you for my next real estate investment."
  },
  {
    author: "Maddie Bautista",
    date: "2 Months Ago",
    text: "We came across Modern Solution when family recommended their services to us. Ryan was our realtor -- who we strongly recommend! My partner and I were first time homebuyers and Ryan facilitated a frictionless, professional, efficient, and fun process. He was so easy to get in touch with when we had any questions, and was a great negotiator as well. A gesture that my partner and I really appreciated was when Ryan noticed that we liked a piece of furniture in the home that we bought, he sent a link of that product to us in case we wanted to buy our own. It really speaks to Ryan's attention to detail and thoughtfulness. If you're a FTHB, do get in touch with Modern Solution and work with Ryan!"
  },
  {
    author: "Fawzi Yahya",
    date: "A Month Ago",
    text: "We had an excellent experience working with Majid at Modern Solution Reality. We put our Condo up for sale during an extremely tough time in the market and were still successful in selling it at a decent price in a short period of time. . Majid was always in regular communication with us, 7-days a week and helped to make the process go as smooth as possible despite the tough market conditions. We are pleased to have him as our agent,  Thanks Modern Solution."
  },
  {
    author: "Fariba N",
    date: "2 Months Ago",
    text: "We recently had the pleasure of working with Hannah Manesh from Modern Solution Reality Inc. to sell our home, and we couldn't be happier with the experience. From the moment we met, Hanna exuded professionalism and expertise. She confidently and easily guided us through every selling process step, always keeping our best interests at heart. With her strategic marketing approach and attention to detail, our home sold quickly and for a fantastic price, higher than our expectations. We highly recommend Hannah Manesh to anyone looking for a top-tier real estate agent who delivers results with high expertise and patience. Thank you, Hannah for making the selling process a breeze!"
  },
  {
    author: "Christal Oliver",
    date: "2 Months Ago",
    text: "Ryan Harkins with Modern Solutions Realty was really friendly and patient when it came to finding that perfect home. He had a ton of current market knowledge and kept me in the loop with new trends and insights. I would definitely recommend Ryan to help with finding your next home or investment!"
  },
  {
    author: "Terry M",
    date: "4 Months Ago",
    text: "Majid Golchini throughout our selling process was incredibly communicative. He promptly answered calls and emails, keeping us informed about every development. His ability to clearly explain complex  details was particularly helpful."
  },
  {
    author: "Julia J",
    date: "4 Months Ago",
    text: "I had Neha Verman as my real-estate agent. She was great. During whole selling process, she was very helpful. She is a trusted sale representative. I had a great experience with her."
  },
  {
    author: "Ihsan Can Aydogan",
    date: "6 Months Ago",
    text: "Ryan was an outstanding guide throughout our condo search in downtown. He made the process stress-free and efficient, never pressuring us at any point. His expertise was invaluable, and he truly made finding our two-bedroom condo a fun experience. Highly recommend!"
  }
];

const counters = [
  { icon: require('../assets/images/icon-1.webp'), value: '1%', text: 'Listings for Sellers' },
  { icon: require('../assets/images/icon-2.webp'), value: '$5,000', text: 'Rebate for buyers' },
  { icon: require('../assets/images/icon-3.webp'), value: '2500+', text: 'Properties Sold' },
  { icon: require('../assets/images/icon-4.webp'), value: '$1,100,000+', text: 'Buyers Received in Cashback' },
  { icon: require('../assets/images/icon-5.webp'), value: '$20,150,000+', text: 'Sellers Saved on Commission' },
];

const slides = [
  {
    image: require('../assets/images/1.webp'),
    title: 'A Home Evaluation and Comparative Market Analysis',
    text: 'Our full-time local real estate agents will assess your home, providing a free and obligation-free written appraisal with the current market value.',
  },
  {
    image: require('../assets/images/2.webp'),
    title: 'Marketing Strategy',
    text: 'Tailoring the strategy to your property, our agents will analyze its unique features and devise a targeted marketing approach while maintaining our promise of listing your property for 1%.',
  },
  {
    image: require('../assets/images/3.webp'),
    title: 'Showing Management',
    text: 'At Modern Solution Realty, our office manages all of your showings and confirms times that work around your busy schedule. Tip: If you want to sell your property, you should allow access to as many people through the property to gain maximum exposure.',
  },
  {
    image: require('../assets/images/4.webp'),
    title: 'The Implementation of Marketing Tactics',
    text: 'We adopt a hands-on approach to every 1% listing, utilizing a range of techniques and services to secure the highest price for your property in the shortest time possible.',
  },
  {
    image: require('../assets/images/5.webp'),
    title: 'Negotiating Offers',
    text: "You won't be alone on deciding what to do when you receive an offer. Our team of agents are highly trained negotiators and will be with you every step of the way, in your corner to get the right price, terms and conditions – in short, to get the right deal for you!",
  },
  {
    image: require('../assets/images/6.webp'),
    title: 'Full MLS Exposure',
    text: 'Your property is seen through every avenue. Listed on all regional boards, the Toronto Regional Real Estate Board, House Sigma, Realtor.ca, and all platforms.',
  },
  {
    image: require('../assets/images/7.webp'),
    title: 'Sale and Closing',
    text: 'We pride ourselves on offering top-notch service from start to finish. Our realtors are available 24/7 to advise and address all your concerns. Our commitment to a great, hassle-free selling experience remains unchanged, even with your 1% listing.',
  },
  {
    image: require('../assets/images/8.webp'),
    title: 'Call Modern Solution',
    text: "Call Modern Solution Realty if you have any questions about buying or selling a home. We'd be happy to help with any of your real estate-related matters.",
  },
];

const SellHomeLanding = () => {
  const [activeReview, setActiveReview] = useState(0);
  const [transitionClass, setTransitionClass] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  const transitionDuration = 300;

  const changeReview = (newIndex) => {
    if (transitionClass) return;
    setTransitionClass("fade-out");
    setTimeout(() => {
      setActiveReview(newIndex);
      setTransitionClass("fade-in");
      setTimeout(() => {
        setTransitionClass("");
      }, transitionDuration);
    }, transitionDuration);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      changeReview(activeReview === reviews.length - 1 ? 0 : activeReview + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeReview, transitionClass]);

  const handleNext = () => {
    changeReview(activeReview === reviews.length - 1 ? 0 : activeReview + 1);
  };

  const handlePrev = () => {
    changeReview(activeReview === 0 ? reviews.length - 1 : activeReview - 1);
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  return (
    <div className="sell-my-home-page page-container">
      <div className="background-decor decor-top-left"></div>
      <div className="background-decor decor-bottom-right"></div>

      <div className="sell-home-landing-content">
        <div className="sell-home-landing-left">
          <div className="sell-home-landing-badge">Low Commission Real Estate in Ontario, Canada</div>
          <h1 className="sell-home-landing-title">
            Sell Smart, Save Big with <span className="brand-color">Modern Solution</span>
          </h1>
          <p className="sell-home-landing-subtitle">
            Stop overpaying for real estate services! Our dedicated local experts offer a game-changing 1% commission and have a proven track record of outperforming the competition 4 to 1. With five-star Google reviews and over $20 million saved for our clients, we deliver results you can trust.
          </p>
          <a href="/contact">
          <button className="sell-home-landing-button" >
            Have A Conversation With Us
          </button></a>
          <a href="/contact">
          <button className="sell-home-landing-button-right">
            Get a Free Home Evaluation
          </button></a>
        </div>

        <div className="sell-home-landing-right">
          <div className="reviews-container">
            <div className="reviews-background"></div>
            <div className={`review-card desktop-only ${transitionClass}`}>
              <div className="review-progress">
                {reviews.map((_, index) => (
                  <div 
                    key={index}
                    className={`progress-dot ${index === activeReview ? 'active' : ''}`}
                  />
                ))}
              </div>
              
              <div className="review-header">
                <img src={googleLogo} alt="Google" className="google-logo" />
                <div className="review-rating">
                  <div className="stars">
                    <span className="star">★</span>
                    <span className="star">★</span>
                    <span className="star">★</span>
                    <span className="star">★</span>
                    <span className="star">★</span>
                  </div>
                  <span className="rating-text">4.8 stars (233 total reviews)</span>
                </div>
              </div>

              <div className="review-content">
                <div className="review-author">{reviews[activeReview].author}</div>
                <div className="review-date">{reviews[activeReview].date}</div>
                <p className="review-text">{truncateText(reviews[activeReview].text, 300)}</p>
              </div>
                <div className="read-more-reviews">

                  <a href="https://g.co/kgs/cEEnYUg">Read All Reviews</a>

                </div>
              <div className="review-navigation">
                <button onClick={handlePrev} className="nav-button prev">←</button>
                <button onClick={handleNext} className="nav-button next">→</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Review Card */}
      <div className={`review-card mobile-only ${transitionClass}`}>
        <div className="review-header">
          <img src={googleLogo} alt="Google" className="google-logo" />
          <div className="review-rating">
            <div className="stars">
              <span className="star">★</span>
              <span className="star">★</span>
              <span className="star">★</span>
              <span className="star">★</span>
              <span className="star">★</span>
            </div>
            <span className="rating-text">4.8 stars (233 total reviews)</span>
          </div>
        </div>

        <div className="review-content">
          <div className="review-author">{reviews[activeReview].author}</div>
          <div className="review-date">{reviews[activeReview].date}</div>
          <p className="review-text">{truncateText(reviews[activeReview].text, 200)}</p>
        </div>

        <div className="read-more-reviews">

                  <a href="https://g.co/kgs/cEEnYUg">Read All Reviews</a>

                </div>

        <div className="review-navigation">
          <button onClick={handlePrev} className="nav-button prev">←</button>
          <button onClick={handleNext} className="nav-button next">→</button>
        </div>
      </div>

      {/* Results Counter Section */}
      <div className="counter-section">
        <h2>Results From Working With Modern Solution</h2>
        <div className="counter-row">
          {counters.slice(0, 3).map((counter, index) => (
            <div key={index} className="counter-box">
              <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
              <h3>{counter.value}</h3>
              <p>{counter.text}</p>
            </div>
          ))}
        </div>
        <div className="counter-row">
          {counters.slice(3, 5).map((counter, index) => (
            <div key={index} className="counter-box">
              <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
              <h3>{counter.value}</h3>
              <p>{counter.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="video-section">
        <h2>Watch Our Featured Video Now on CP24!</h2>
        </div>

        
      {/* Video Section */}
      <div className="video-container">
        
        {!isVideoPlaying && (
          <div className="unique-play-button" onClick={handlePlayVideo}>
            <FaPlay className="unique-play-icon" />
          </div>
        )}
        <video
          ref={videoRef}
          width="100%"
          height="auto"
          controls
          playsInline={true}
          preload="metadata"
          poster={require('../assets/images/video-poster.png')}
          onPlay={() => setIsVideoPlaying(true)}
          onPause={() => setIsVideoPlaying(false)}
        >
          <source src={require('../assets/videos/video.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

{/* Savings Calculator Section */}
<div className="calculator-section">
        <h2>
          On Average, our clients <span className="partners-text">saved</span> $18,000.
        </h2>
        <h3>How Much Will You Save?</h3>
        <CommissionCalculator />
      </div>
<div className="steps-container">
        <h2>Let's Break It Down - Step By Step!</h2>
  {slides.map((slide, index) => (
    <div key={index} className={`content-image-section ${index % 2 !== 0 ? 'text-right' : ''} animate-on-scroll`}>
      <div className="text-content">
        <h2>{`${index + 1}. ${slide.title}`}</h2>
        <p>{slide.text}</p>
      </div>
      <div className="image-content">
        <img src={slide.image} alt={`Slide ${index + 1}`} className="image-placeholder" />
      </div>
    </div>
  ))}
</div>

<div className="contact-section">
        <div className="contact-grid">
          {/* Contact Information Box */}
          <div className="contact-info-box">
            <h2>Contact Information</h2>
            <div className="contact-divider"></div>
            
            
            <a href="tel:9058975000" className="contact-item">
              <Phone className="contact-icon" />
              <span>(905) 897-5000</span>
            </a>
            
            <a href="/contact" className="contact-item">
              <Mail className="contact-icon" />
              <span>info@modernsolution.ca</span>
            </a>

            <a href="#" className="contact-item">
  <Clock className="contact-icon" />
  <span>Open from 9:00 AM - 5:00 PM</span>
</a>
            
            <a 
              href="https://www.google.com/maps/search/?api=1&query=3466+Mavis+Rd+Unit+1,+Mississauga,+ON+L5C+1T8" 
              target="_blank" 
              rel="noopener noreferrer"
              className="contact-item"
            >
              <MapPin className="contact-icon" />
              <span>3466 Mavis Rd Unit 1, Mississauga, ON L5C 1T8</span>
            </a>
          </div>

          {/* Contact Form */}
          <div className="contact-form-container">
          <h2>Send Us A Message</h2>
          <div className="contact-divider"></div>
            <ContactForm />
          </div>
        </div>
      </div>

<CityWidget />
      {/* Featured Listings Section */}
      <h2 className="featured-listings-heading">Modern Solution Listings</h2>
      <ListingWidget />

      {/* Toronto Star Section */}
      <div className="toronto-star-wrapper">
        <div className="toronto-star-container">
          <div className="home-page-toronto-star">
            <img src={thestarLogo} alt="The Star Logo" className="thestar-logo" />
            <h2 className="toronto-star-heading">Modern Solution Featured in Toronto Star</h2>
            <p className="toronto-star-paragraph">
              In a real estate world where outdated commission rates have long been the norm,
              Modern Solution Realty Inc. is proud to offer a refreshing and cost-effective
              alternative.
              
              Traditional commission structures, often hovering around five to six per cent, have
              burdened home sellers with exorbitant fees. For instance, selling a $1,000,000 home with
              a standard five per cent commission could cost a staggering $50,000 — an expense that
              seems increasingly unreasonable in today's dynamic market.
            </p>
            <a
              href="https://www.thestar.com/sponsored-sections/a-modern-solution-approach-to-real-estate-with-one-per-cent-commission/article_1ca3e32e-6eec-11ef-84ea-53f224f162c8.html"
              target="_blank"
              rel="noopener noreferrer"
              className="read-more-link"
            >
              Read More
            </a>
          </div>
          <div className="toronto-star-image-column">
            <img 
              src={require('../assets/images/thestar-office.webp')} 
              alt="The Star Office" 
              className="toronto-star-image"
            />
          </div>
        </div>
      </div>
      <PhoneButton />
      <Footer />
    </div>
  );
};

export default SellHomeLanding;