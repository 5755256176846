// PhoneButton.jsx
import React from 'react';
import { Phone } from 'lucide-react';
import './PhoneButton.css';

const PhoneButton = () => {
  return (
    <div className="floating-phone-container">
      <a href="tel:9058975000" className="floating-phone-button">
        <Phone className="phone-icon" />
      </a>
    </div>
  );
};

export default PhoneButton;